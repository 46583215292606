const apisAndUrls = {
  cachedApi: {
    baseUrl: "https://testcached-api.katholiekonderwijs.vlaanderen",
  },
  api: "https://testapi.katholiekonderwijs.vlaanderen",
  customcurapi: {
    baseUrl: "https://testcustomcurricula-api.katholiekonderwijs.vlaanderen",
    groupBy: 70,
  },
  activityplansapi: {
    baseUrl: "https://testactivityplans-api.katholiekonderwijs.vlaanderen",
  },
  securityapi: {
    baseUrl: "https://testbeveiliging-nodejs.katholiekonderwijs.vlaanderen",
  },
  myVsko: "http://testmijn.katholiekonderwijs.vlaanderen",
};

export default apisAndUrls;
